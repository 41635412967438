import React, { useState, useEffect } from "react";
import "./Search.css";
import ViewButton from "./ViewButton";
import API_URL from "../api";

const Search = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (searchQuery) {
        try {
          const apiKey = localStorage.getItem("password");

          const response = await fetch(
            `${API_URL}/search-uploads/${searchQuery}`,
            {
              headers: {
                "X-API-KEY": apiKey,
              },
            }
          );

          if (response.ok) {
            const data = await response.json();
            setSearchResults(data);
          } else {
            console.error("Failed to fetch data");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="search-page">
      <h1>Suche</h1>
      <input
        type="text"
        value={searchQuery}
        onChange={handleSearchQueryChange}
        placeholder="Gib einen Vornamen, Nachnamen oder beides ein"
      />
      <ul>
        {searchResults.map((result) => (
          <li key={result.id}>
            <p>Vorname: {result.firstName}</p>
            <p>Nachname: {result.lastName}</p>
            <p>ID: {result.id}</p>
            <ViewButton
              id={result.id}
              firstName={result.firstName}
              lastName={result.lastName}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Search;
