import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Upload from "./Upload/Upload";
import Search from "./Search/Search";
import DisplayImage from "./DisplayImage/DisplayImage";
import Login from "./Login/Login";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  return (
    <div id="main-container">
      <Navbar />
      <div id="content-container">
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Upload />} />
            <Route path="/search" element={<Search />} />
            <Route
              path="/display/:id/:firstName/:lastName"
              element={<DisplayImage />}
            />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
