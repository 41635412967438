import React from "react";
import API_URL from "../api";
import { useNavigate } from "react-router-dom";

const DeleteButton = ({ id }) => {
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      const apiKey = localStorage.getItem("password");

      const response = await fetch(`${API_URL}/delete-upload/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
      });

      if (response.ok) {
        alert("Die Karte wurde erfolgreich gelöscht!");
      } else {
        alert("Fehler beim Löschen der Karte!");
      }
    } catch (error) {
      alert("Fehler beim Löschen der Karte!");
    }
    navigate("/");
  };

  return <button onClick={handleClick}>Löschen</button>;
};

export default DeleteButton;
