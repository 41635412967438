import React, { useState, useRef } from "react";
import "./Upload.css";
import API_URL from "../api";

const Upload = () => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    image: null,
  });

  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const value =
      e.target.name === "image" ? e.target.files[0] : e.target.value;
    setForm({ ...form, [e.target.name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", form.image);
    formData.append("firstName", form.firstName);
    formData.append("lastName", form.lastName);

    try {
      const response = await fetch(`${API_URL}/create-upload/upload`, {
        method: "POST",
        body: formData,
        headers: {
          "X-API-KEY": localStorage.getItem("password"),
        },
      });

      if (!response.ok) {
        throw new Error("Fehler beim Hochladen der Karte!");
      }

      alert("Karte erfolgreich hochgeladen!");
      setForm({
        firstName: "",
        lastName: "",
        image: null,
      });

      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      alert("Fehler beim Hochladen der Karte!");
    }
  };

  return (
    <div className="upload-form">
      <h1>Willkommenskarte Hochladen</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Vorname:
          <input
            type="text"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Nachname:
          <input
            type="text"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Karte:
          <input
            type="file"
            name="image"
            ref={fileInputRef}
            onChange={handleChange}
          />
        </label>
        <br />
        <button type="submit">Speichern</button>
      </form>
    </div>
  );
};

export default Upload;
