import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import "./DisplayImage.css";
import API_URL from "../api";
import DeleteButton from "./DeleteButton";

const DisplayImage = () => {
  const [imageSrc, setImageSrc] = useState("");
  const { id, firstName, lastName } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`${API_URL}/get-upload/${id}`, {
          headers: {
            "X-API-KEY": localStorage.getItem("password"),
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          setImageSrc(URL.createObjectURL(blob));
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    };

    fetchImage();

    return () => URL.revokeObjectURL(imageSrc);
  }, [id]);

  if (!error) {
    return (
      <div className="display-image-container">
        <h1>Willkommenskarte</h1>
        <div className="info-container">
          <p>Vorname: {firstName}</p>
          <p>Nachname: {lastName}</p>
          <DeleteButton id={id}></DeleteButton>
        </div>
        <img
          className="image"
          src={imageSrc}
          alt={`Karte von ${firstName} ${lastName}`}
        />
      </div>
    );
  } else {
    return <h1>Karte nicht gefunden</h1>;
  }
};

export default DisplayImage;
