import API_URL from "../api";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import "./Login.css";
const Login = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: "GET",
        headers: {
          "X-API-KEY": password,
        },
      });

      const data = await response.json();

      if (data.error) {
        alert("Falsches Passwort!");
      } else {
        localStorage.setItem("password", password);
        alert("Erfolgreich eingeloggt!");
        navigate("/");
      }
    } catch (error) {
      alert("Fehler beim Login!");
    }
  };

  return (
    <div id="login-div">
      <h1>Bitte gib das Passwort ein</h1>
      <p>
        Solltest du das Passwort nicht wissen, bekommst du es von Claudia,
        Pascal oder Till
      </p>
      <form>
        <input
          type="password"
          value={password}
          placeholder="Passwort"
          onChange={(e) => setPassword(e.target.value)}
        />
        <br></br>
        <button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
