import React from "react";
import { useNavigate } from "react-router-dom";

const ViewButton = ({ id, firstName, lastName }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/display/${id}/${firstName}/${lastName}`);
  };

  return (
    <button onClick={handleClick} className="view-button">
      Karte Ansehen
    </button>
  );
};

export default ViewButton;
